<template>
  <div class="form-stepper form-stepper-channel_subscription">
    <v-form
      ref="form"
      v-model="valid"
      class="form pa-6"
    >
      <p>{{$t('CHANNEL_SUBSCRIPTION.INTRODUCTION_TEXT')}}</p>
      <div class="pa-0">
        <v-row>
          <v-col cols="8">
            <v-text-field
              dense
              v-model="channel_id"
              :rules="[$rules.required, $rules.maxlength(255)]"
              :label="$t('INPUT.TITLE')"
              :placeholder="$t('INPUT.TITLE')"
              outlined
              required
            />
          </v-col>
        </v-row>
      </div>

      <div class="pa-0">
        <v-row>
          <v-col cols="8">
            <v-checkbox
              class="mt-0 pt-0"
              v-model="use_xtramile_account"
              :label="$t('INPUT.AUTOREFRESH')"
              :placeholder="$t('INPUT.AUTOREFRESH')"
              outlined
            />
          </v-col>
        </v-row>
      </div>

    </v-form>

    <v-divider />
    <v-toolbar
      color="transparent"
      flat
      height="70px"
      class="form-footer"
    >
      <v-spacer/>
      <v-btn
        color="primary custom"
        class="mr-4"
        rounded
        depressed
        :disabled="!valid && !loading"
        large
        @click="validate"
      >
        <strong>{{ $t('CONTINUE') }}</strong>
        <v-icon class="ml-4">mdi-arrow-right</v-icon>
      </v-btn>

    </v-toolbar>

  </div>
</template>

<script>
export default {
  name: 'FormChannelSubscriptionStepperIntro',
  data: () => ({
    loading: false,
    valid: false,
    //
    use_xtramile_account: false,
    channel_id: null,
  }),
  props: {
    values: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    values (n) {
      this.init()
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      Object.keys(this.values)
        .forEach((key) => {
          if (typeof this.values[key] !== 'undefined' && typeof this[key] !== 'undefined') {
            this[key] = this.values[key]
          }
        })
    },
    validate () {
      if (!this.$refs.form.validate()) {
        this.$emit('validation', false)
        return
      }

      const data = {}
      data.channel_id = this.channel_id || undefined
      data.use_xtramile_account = this.use_xtramile_account || undefined

      this.$emit('childData', data)
      this.$emit('validation', true)
      this.$emit('next')
    }
  },
}
</script>

<style scoped>

</style>
